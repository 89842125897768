import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { actions as patientsActions, selectors as patientsSelectors } from '..';
import { BasicLayout } from '../../../components/layouts/BasicLayout';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import { Paragraph, ParagraphTitle, Title } from '../../../components/styled';
import ManImage from '../../../assets/man.png';
import UserInfoPanel from '../../../components/UserInfoPanel';
import Button from '../../../components/Button';
import GlucoseMeter from '../components/GlucoseMeter';
import { glucoseRange } from '../../../definitions/glucoseRange';
import LogBook from '../components/LogBook';
import MedicineAssignment from '../components/MedicineAssignment';
import Chart from '../components/Chart';
import { Measurement } from '../types';

const PatientDetailsView: FunctionComponent<RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const isFetching = useSelector(patientsSelectors.selectIsFetching);
  const isError = useSelector(patientsSelectors.selectIsError);
  const patient = useSelector(patientsSelectors.selectPatient);

  useEffect(() => {
    dispatch(patientsActions.patientDetailsRequest({ patientId: id }));
  }, []);

  if (isFetching)
    return (
      <>
        <Loader />
      </>
    );

  return (
    <BasicLayout bgWhite>
      <Page container>
        <Row className="justify-content-between">
          <Title className="" color="primary" shadow={false}>
            My Doctor
          </Title>
          <Link to="/patients">
            <ParagraphTitle className="text-right" color="primary">
              Lista pazienti
            </ParagraphTitle>
          </Link>
        </Row>
        <Row className="mt-3 align-items-center">
          <Col className="d-flex align-items-center flex-column">
            <UserInfoPanel
              photo={ManImage}
              name={patient?.displayName}
              gender={patient?.gender}
              country={patient?.country}
              birthdate={patient?.birthdate}
            />
            <Button name="user-disable" btnStyle="link" text="Disattiva" style={{ color: 'red' }} />
          </Col>
          <Col>
            <MedicineAssignment disabled />
          </Col>
          <Col>
            <GlucoseMeter value={patient?.lastMeasurement?.value} glucoseRange={glucoseRange} />
          </Col>
        </Row>
        <Row>
          <Chart glucoseRange={glucoseRange} measurements={patient?.measurements || []} />
        </Row>
        <Row>
          <LogBook glucoseRange={glucoseRange} measurements={patient?.measurements || []} />
        </Row>
      </Page>
    </BasicLayout>
  );
};

export default PatientDetailsView;
