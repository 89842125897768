import React, { FunctionComponent, HTMLProps } from 'react';
import { StyledInputProps, StyledInput, StyledInputWrapper } from './styled';

export interface InputProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange' | 'ref' | 'as'> {
  password?: boolean;
  onChange?: (value: string) => void;
}

const Input: FunctionComponent<InputProps & StyledInputProps> = ({
  password,
  name = 'input',
  placeholder = 'Insert text here...',
  width,
  onChange = (value) => {},
  ...props
}) => {
  return (
    <StyledInputWrapper>
      <StyledInput
        type={(password && 'password') || 'text'}
        name={name}
        placeholder={placeholder}
        width={width || 200}
        onChange={(event) => onChange(event.currentTarget.value)}
        {...props}
      />
    </StyledInputWrapper>
  );
};

export default Input;
