import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Container } from './styled';
import { Bubble } from 'react-chartjs-2';
import { GlucoseRange } from '../../../../definitions/glucoseRange';
import { Measurement } from '../../types';
import moment from 'moment';
import { Row } from 'react-bootstrap';
import Button from '../../../../components/Button';
import { uniqBy } from 'lodash';

export interface ChartProps {
  glucoseRange: GlucoseRange;
  measurements: Measurement[];
}

const Chart: FunctionComponent<ChartProps> = ({ glucoseRange, measurements }) => {
  const [timeRange, setTimeRange] = useState<'Today' | 'Week' | 'Month'>('Today');
  const [data, setData] = useState({});

  // console.log(data);

  const msToday = useMemo(
    () => ({
      label: moment(Date.now()).format('DD/MM/YYYY'),
      backgroundColor: 'rgba(75,192,192,1)',
      data: measurements
        .filter((m) => moment(m.dateTime).isSame(Date.now(), 'day'))
        .map((ms) => ({
          x: ms.dateTime,
          y: Number(ms.value),
          r: 10,
        })),
    }),
    []
  );
  const msWeek = useMemo(() => {
    const msFiltered = measurements.filter((m) =>
      moment(m.dateTime).isBetween(Date.now(), m.dateTime, 'week', '[]')
    );

    const days = msFiltered.map((ms) => ({
      label: moment(ms.dateTime).format('DD/MM/YYYY'),
      backgroundColor: 'rgba(75,192,192,1)',
      data: msFiltered
        .filter((mf) => moment(mf.dateTime).format('DD') == moment(ms.dateTime).format('DD'))
        .map((m) => ({
          x: m.dateTime,
          y: Number(m.value),
          r: 10,
        })),
    }));
    console.log(measurements);

    console.log(msFiltered);
    return uniqBy(days, (day) => day.label);
  }, []);

  const msMonth = useMemo(() => {
    const msFiltered = measurements.filter((m) =>
      moment(m.dateTime).isBetween(m.dateTime, Date.now(), 'month', '[)')
    );

    const months = msFiltered.map((ms) => ({
      label: moment(ms.dateTime).format('DD/MM/YYYY'),
      backgroundColor: 'rgba(75,192,192,1)',
      data: msFiltered
        .filter((mf) => moment(mf.dateTime).format('DD') == moment(ms.dateTime).format('DD'))
        .map((m) => ({
          x: m.dateTime,
          y: Number(m.value),
          r: 10,
        })),
    }));

    return uniqBy(months, (day) => day.label);
  }, []);

  useEffect(() => {
    setData({
      datasets:
        (timeRange == 'Today' && [msToday]) ||
        (timeRange == 'Week' && msWeek) ||
        (timeRange == 'Month' && msMonth),
    });
  }, [timeRange]);

  return (
    <Container>
      <Row className="d-flex justify-content-end">
        <section className="d-inline-flex">
          <Button
            name="today-button"
            text="Oggi"
            btnStyle="link"
            style={{ border: (timeRange == 'Today' && '1px solid') || 'initial' }}
            onClick={() => setTimeRange('Today')}
          />
          <Button
            name="week-button"
            text="Settimana"
            btnStyle="link"
            style={{ border: (timeRange == 'Week' && '1px solid') || 'initial' }}
            onClick={() => setTimeRange('Week')}
          />
          <Button
            name="month-button"
            text="Mese"
            btnStyle="link"
            style={{ border: (timeRange == 'Month' && '1px solid') || 'initial' }}
            onClick={() => setTimeRange('Month')}
          />
        </section>
      </Row>
      <Bubble
        height={100}
        data={data}
        options={{
          scales: {
            xAxes: [
              {
                type: 'time',
                bounds: 'ticks',
                time: {
                  tooltipFormat: 'YYYY-MM-DD HH:mm',
                  displayFormats: {
                    quarter: 'MMM YYYY',
                    hour: 'HH:mm',
                  },
                },
              },
            ],
          },
        }}
      />
    </Container>
  );
};

export default Chart;
