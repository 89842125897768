import { FunctionComponent } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import styled from 'styled-components';

export interface StyledModalBodyProps {
  gradient?: boolean;
  height?: string;
}

export const StyledModalBody: FunctionComponent<StyledModalBodyProps> = styled(Modal.Body)`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => props.height || '530px'};
  padding-top: 7%;
  border-radius: ${({ theme }) => theme.borders.circle};
  background: ${({ theme, gradient }) =>
    (gradient &&
      `linear-gradient(
    ${theme.colors.secondary}, ${theme.colors.primary}
  )`) ||
    'transparent'};
` as any;
