import React, { FunctionComponent, ReactElement } from 'react';
import { ParagraphTitle, Text } from '../../../../components/styled';
import { Row, Table } from 'react-bootstrap';
import Patient, { PatientProps } from '../Patient';
import { PatientType } from '../../types';
import { glucoseRange } from '../../../../definitions/glucoseRange';

export interface PatientListProps {
  patients: PatientType[] | null;
  onPatientClick: (patientId: string) => void;
}

const PatientList: FunctionComponent<PatientListProps> = ({
  patients,
  onPatientClick = () => {},
}) => {
  return (
    <>
      {patients && patients.length > 0 && (
        <Table className="my-4" hover>
          <thead>
            <tr>
              <th className="text-center">
                <Text color="primary"></Text>
              </th>
              <th className="text-center">
                <Text color="primary">Nome</Text>
              </th>
              <th className="text-center">
                <Text color="primary">Glucosio</Text>
              </th>
              <th className="text-center">
                <Text color="primary">codice</Text>
              </th>
              <th className="text-center">
                <Text color="primary">Stato</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {patients &&
              patients.map((patient) => (
                <Patient
                  key={patient.userId}
                  mode="row"
                  patient={patient}
                  glucoseRange={glucoseRange}
                  onClick={onPatientClick}
                />
              ))}
          </tbody>
        </Table>
      )}
      {patients && patients.length == 0 && (
        <Row className="justify-content-center mt-5">
          <ParagraphTitle color="primary" width="350px">
            Nessun paziente disponibile
          </ParagraphTitle>
        </Row>
      )}
    </>
  );
};

export default PatientList;
