import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectEmail = createSelector(
  (state: RootState) => state.auth.email,
  (email) => email
);

export const selectIsFetching = createSelector(
  (state: RootState) => state.auth.isFetching,
  (isFetching) => isFetching
);

export const selectIsLogged = createSelector(
  (state: RootState) => Boolean(state.auth.user),
  (isLogged) => isLogged
);

export const selectSigninSuccessful = createSelector(
  (state: RootState) => state.auth.signinSuccessful,
  (signinSuccessful) => signinSuccessful
);

export const selectUser = createSelector(
  (state: RootState) => state.auth.user,
  (user) => user
);

export const selectIsError = createSelector(
  (state: RootState) => state.auth.isError,
  (isError) => isError
);

export const selectError = createSelector(
  (state: RootState) => state.auth.error,
  (error) => error
);
