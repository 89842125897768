/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';

const config = {
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SEND_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  public app: firebase.app.App;
  public auth: firebase.auth.Auth;
  public db: firebase.database.Database;
  public firestore: firebase.firestore.Firestore;

  constructor() {
    this.app = firebase.initializeApp(config);

    this.auth = firebase.auth();
    this.db = firebase.database();
    this.firestore = firebase.firestore();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) => this.auth.currentUser?.updatePassword(password);

  // *** User API ***
  user = (uid: string) => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');
  profile = () =>
    this.firestore.collection('users').where('userId', '==', this.auth.currentUser?.uid).get();
  patients = (doctorCode?: string) =>
    this.firestore
      .collection('users')
      .where('role', '!=', 'doctor')
      .where('doctorCode', '==', doctorCode)
      .get();
  measurements = (patientId: string) =>
    this.firestore
      .collection('logbook')
      .orderBy('dateTime', 'desc')
      .where('userId', '==', patientId)
      .get();
  lastMeasurement = (patientId: string) =>
    this.firestore
      .collection('logbook')
      .orderBy('dateTime', 'desc')
      .where('type', '==', 'blood')
      .where('userId', '==', patientId)
      .limit(1)
      .get();
}

export default Firebase;
