import React, { CSSProperties, FunctionComponent } from 'react';
import { ContentStyled } from './styled';
import { Container } from 'react-bootstrap';

export interface PageProps {
  container?: boolean;
  style?: CSSProperties;
}

const Page: FunctionComponent<PageProps> = ({ children, container = false, style }) => {
  return (
    <ContentStyled style={style}>
      {container && (
        <ContentStyled>
          <Container>{children}</Container>
        </ContentStyled>
      )}
      {!container && <ContentStyled>{children}</ContentStyled>}
    </ContentStyled>
  );
};

export default Page;
