import styled, { css } from 'styled-components';

export interface StyledFormProps {
  viewMode?: 'row' | 'column';
}

export const StyledForm = styled.form<StyledFormProps>`
  display: flex;
  justify-content: space-between;

  ${({ theme, viewMode }) => {
    if (viewMode == 'column')
      return css`
        flex-flow: column;
        align-items: center;
        justify-items: center;
      `;
  }}
`;
