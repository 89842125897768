import { configureStore, getDefaultMiddleware, Store } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = Store;

const sagaMiddleware = createSagaMiddleware();

const rootPersistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store: AppStore = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware({ serializableCheck: false }), sagaMiddleware],
  devTools: process.env.NODE_ENV === 'development',
  // use for debugging https://github.com/jhen0409/react-native-debugger
});

sagaMiddleware.run(rootSaga);

const persistor: Persistor = persistStore(store);

export { persistor };

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
