import React, { FunctionComponent } from 'react';
import { StyledMenuContainer } from './styled';

export interface TopMenuProps {
  position?: 'left' | 'right';
}

const TopMenu: FunctionComponent<TopMenuProps> = ({ children, position = 'right' }) => {
  return <StyledMenuContainer position={position}>{children}</StyledMenuContainer>;
};

export default TopMenu;
