import styled from 'styled-components';

export const StyledFooter = styled.footer`
  width: 100%;
  height: 200px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const StyledIcon = styled.img<{ width?: number; height?: number }>`
  height: ${({ height }) => (height && `${height}px`) || '24px'};
  width: ${({ width }) => (width && `${width}px`) || '24px'};
  filter: brightness(0) invert(1);
  margin: 10px;
`;
