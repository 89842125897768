import React, { FunctionComponent } from 'react';
import { Col } from 'react-bootstrap';
import { Overlay } from '../styled';
import { StyledRow } from './styled';

export interface SectionProps {
  className?: string;
  gradient?: boolean;
  image?: string;
  imagePosition?: 'left' | 'right';
  overlay?: boolean;
}

const Section: FunctionComponent<SectionProps> = ({
  children,
  className = '',
  gradient = false,
  image,
  imagePosition,
  overlay,
}) => {
  return (
    <StyledRow className={'d-flex align-items-center my-5 ' + className} gradient={gradient}>
      {image && (
        <>
          <Col md={6}>
            {image && imagePosition && imagePosition == 'left' && <img src={image} width="100%" />}
            {image && imagePosition && imagePosition != 'left' && children}
          </Col>
          <Col md={6}>
            {image && imagePosition && imagePosition == 'right' && <img src={image} width="100%" />}
            {image && imagePosition && imagePosition != 'right' && children}
            {overlay && <Overlay />}
          </Col>
        </>
      )}
      {!image && children}
    </StyledRow>
  );
};

export default Section;
