import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { app, FirebaseContext } from './contexts/FirebaseContext';
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import 'moment/locale/it';
import moment from 'moment';

moment.locale('it');

persistor.purge();

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={app}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
