import React from 'react';
import Firebase from './Firebase';

const FirebaseContext = React.createContext<Firebase | null>(null);

const app = new Firebase();

export { app };
export { FirebaseContext };

export default Firebase;
