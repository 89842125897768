import React, { FunctionComponent, useCallback } from 'react';
import { StyledHeader, StyledLogo } from './styled';
import Logo from '../../assets/logo.png';
import TopMenu from '../TopMenu';
import LoginForm from '../../features/Auth/components/forms/LoginForm';
import UserLogged from '../../features/Auth/components/UserLogged';
import { useDispatch, useSelector } from 'react-redux';
import { actions as authActions, selectors as authSelectors } from '../../features/Auth';
import { useHistory } from 'react-router-dom';

const Header: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(authSelectors.selectIsLogged);
  const user = useSelector(authSelectors.selectUser);
  const history = useHistory();

  const logoutHandler = useCallback(() => {
    dispatch(authActions.logoutRequest());
    history.push('/');
  }, []);

  return (
    <StyledHeader>
      <StyledLogo src={Logo} />
      <TopMenu position="right">
        {!isLogged && <LoginForm type="short" />}
        {isLogged && <UserLogged name={user?.name} onLogout={logoutHandler} />}
      </TopMenu>
    </StyledHeader>
  );
};

export default Header;
