import styled, { css, DefaultTheme } from 'styled-components';

export type BtnStyle = 'link' | 'link-outline' | 'filled' | 'filled-inverted';

export interface StyledButtonProps {
  btnStyle: BtnStyle;
  color?: keyof DefaultTheme['colors'];
}

export const StyledButton = styled.button<StyledButtonProps>`
  appearance: none;
  outline: none;
  min-width: 180px;
  color: ${({ theme }) => theme.colors.primary};
  border: ${({ theme }) => theme.colors.secondary} solid 1px;
  border-radius: 24px;
  padding: 5px 20px;
  font-weight: bold;
  /* box-shadow: 1px 1px 1px black; */

  ${({ btnStyle, theme, color }) => {
    if (btnStyle == 'link')
      return css`
        border: none;
        min-width: auto;
        padding: none;
        background-color: transparent;
        margin-left: 10px;
        color: ${(color && theme.colors[color]) || theme.colors['primary']};
        font-weight: bold;
        text-shadow: none;
      `;

    if (btnStyle == 'link-outline')
      return css`
        background-color: transparent;
        color: ${(color && theme.colors[color]) || theme.colors['light']};
        font-weight: lighter;
      `;

    if (btnStyle == 'filled-inverted')
      return css`
        background-color: ${theme.colors.light};
        color: ${(color && theme.colors[color]) || theme.colors['primary']};
        font-weight: bold;
      `;
  }}
`;
