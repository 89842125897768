import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme, GlobalStyle } from './definitions/styled';
import NoMatchView from './features/Common/views/NoMatchView';
import HomeView from './features/Home/views/HomeView';
import PatientsView from './features/Patients/views/PatientsView';
import PatientDetailsView from './features/Patients/views/PatientDetailsView';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route exact path="/" component={HomeView} />
          <Route exact path="/patients" component={PatientsView} />
          <Route path="/patients/:id" component={PatientDetailsView} />
          <Route path="*" component={NoMatchView} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
