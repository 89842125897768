import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal, { ModalProps } from '../../../../components/Modal';
import SignupForm from '../forms/SignupForm';

const SignupModal: FunctionComponent<ModalProps> = (props) => {
  return (
    <Modal gradient height="800px" {...props}>
      <Row style={{ width: '65%' }}>
        <Col className="pl-0">
          <h4>Signup</h4>
          <h6>Registration form my doctor platform</h6>
        </Col>
      </Row>
      <SignupForm />
    </Modal>
  );
};

export default SignupModal;
