import React, { ChangeEvent, FunctionComponent } from 'react';
import { StyledContainer, StyledIcon, StyledSearch } from './styled';
import SearchIcon from '../../assets/search.png';

export interface SearchProps {
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Search: FunctionComponent<SearchProps> = ({ onSearch = () => {} }) => {
  return (
    <StyledContainer>
      <StyledSearch type="text" onChange={onSearch} />
      <StyledIcon src={SearchIcon} />
    </StyledContainer>
  );
};

export default Search;
