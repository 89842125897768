import React, { FunctionComponent, useCallback, useState } from 'react';
import { Form as BSForm, Row } from 'react-bootstrap';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import { actions, selectors } from '../..';
import { useDispatch, useSelector } from 'react-redux';

export interface LoginFormProps {
  type?: 'short' | 'full';
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ type = 'full' }) => {
  const [values, setValues] = useState<Record<string, string>>({});
  const isError = useSelector(selectors.selectIsError);
  const dispatch = useDispatch();

  const submitHandler = useCallback(() => {
    dispatch(actions.setEmail(values['email']));
    dispatch(actions.loginRequest({ email: values['email'], password: values['password'] }));
  }, [values]);

  switch (type) {
    case 'short':
      return (
        <Form viewMode="row">
          <Input
            name="email"
            placeholder="contact@email.com"
            onChange={(value) => setValues({ ...values, ['email']: value })}
          />
          <Input
            name="password"
            placeholder="password"
            password
            width={100}
            onChange={(value) => setValues({ ...values, ['password']: value })}
          />
          <Button name="login-button" text="Login" btnStyle="link" onClick={submitHandler} />
        </Form>
      );
      break;
    case 'full':
      return (
        <Form viewMode="column">
          <Input
            themeColor="light"
            name="email"
            placeholder="contact@email.com"
            width={300}
            type="email"
            onChange={(value) => setValues({ ...values, ['email']: value })}
          />
          <Input
            themeColor="light"
            name="password"
            placeholder="password"
            password
            width={300}
            onChange={(value) => setValues({ ...values, ['password']: value })}
          />
          {isError && (
            <Row>
              <span className="text-danger">Credenziali non valide</span>
            </Row>
          )}
          <Button
            className="my-3"
            name="login-button"
            text="Login"
            btnStyle="filled-inverted"
            onClick={submitHandler}
          />
          <span>
            <strong>Forgot </strong>
            <span>password ?</span>
          </span>
          <Button
            className="mt-3"
            name="signup-button"
            text="Signup"
            btnStyle="link-outline"
            onClick={submitHandler}
          />
        </Form>
      );
      break;
    default:
      return null;
  }
};

export default LoginForm;
