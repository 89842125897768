import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Form as BSForm, Row } from 'react-bootstrap';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/Input';
import { actions, selectors } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-modern-calendar-datepicker';

export interface LoginFormProps {
  type?: 'short' | 'full';
}

const SignupForm: FunctionComponent<LoginFormProps> = ({ type = 'full' }) => {
  const [values, setValues] = useState<Record<string, string>>({});
  const isError = useSelector(selectors.selectIsError);
  const dispatch = useDispatch();

  // useEffect(() => {

  // }, [isError]);

  const submitHandler = useCallback(
    () =>
      dispatch(
        actions.signupRequest({
          username: values['name'],
          email: values['email'],
          password: values['password'],
          nationalDoctorCode: values['national-doctor-code'],
        })
      ),
    [values]
  );

  return (
    <Form viewMode="column">
      <Input
        themeColor="light"
        name="name"
        placeholder="Name"
        width={300}
        type="text"
        onChange={(value) => setValues({ ...values, ['name']: value })}
      />
      <Input
        themeColor="light"
        name="dateOfBirth"
        placeholder="Date of Birth"
        width={300}
        type="date"
        onChange={(value) => setValues({ ...values, ['dateOfBirth']: value })}
      />
      <Input
        themeColor="light"
        name="phone"
        placeholder="Phone"
        type="phone"
        width={300}
        onChange={(value) => setValues({ ...values, ['phone']: value })}
      />
      <Input
        themeColor="light"
        name="email"
        placeholder="Email"
        width={300}
        type="email"
        onChange={(value) => setValues({ ...values, ['email']: value })}
      />
      <Input
        themeColor="light"
        name="password"
        placeholder="Password"
        width={300}
        type="password"
        onChange={(value) => setValues({ ...values, ['password']: value })}
      />
      <Input
        themeColor="light"
        name="national-doctor-code"
        placeholder="National doctor code"
        width={300}
        type="text"
        onChange={(value) => setValues({ ...values, ['national-doctor-code']: value })}
      />
      {/* {isError && (
        <Row>
          <span className="text-danger">Credenziali non valide</span>
        </Row>
      )} */}
      <Button
        className="mt-3"
        name="signup-button"
        text="Affiliation"
        btnStyle="filled"
        onClick={submitHandler}
      />
      <Button
        className="mt-3 text-white"
        name="cancel-button"
        text="Cancel"
        btnStyle="link"
        onClick={submitHandler}
      />
    </Form>
  );
};

export default SignupForm;
