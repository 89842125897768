import styled from 'styled-components';

export const StyledContainer = styled.article`
  display: flex;
  flex: 1;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledSearch = styled.input`
  border-radius: ${({ theme }) => theme.borders.circle};
  outline: none;
  padding: 0 15px;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  width: 100%;
  height: 30px;
`;

export const StyledIcon = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  margin-right: 15px;
`;
