import styled from 'styled-components';

export const StyledInputWrapper = styled.section`
  margin: 30px 5px;
`;

export interface StyledInputProps {
  themeColor?: 'normal' | 'light';
}

export const StyledInput = styled.input<StyledInputProps>`
  appearance: none;
  border: none;
  outline: none;
  padding: 5px 10px;
  background: transparent;
  border-bottom: 1px solid;
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  ${({ theme, themeColor }) =>
    (themeColor == 'light' && theme.colors.light) || theme.colors.primary};
  color: ${({ theme, themeColor }) =>
    (themeColor == 'light' && theme.colors.light) || theme.colors.primary};
  width: ${(props) => `${props.width}px`};

  ::placeholder {
    color: ${({ theme, themeColor }) =>
      (themeColor == 'light' && theme.colors.light) || theme.colors.primary};
  }
`;
