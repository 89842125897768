import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Image, Text } from '../../../../components/styled';
import { Card } from '../../../../components/styled/Card';
import BloodIcon from '../../../../assets/blood.png';
import { GlucoseRange } from '../../../../definitions/glucoseRange';
import { useTheme } from 'styled-components';
import { Container } from './styled';

export interface GlucoseMeterProps {
  value?: number;
  insuline?: number;
  glucoseRange: GlucoseRange;
}

const GlucoseMeter: FunctionComponent<GlucoseMeterProps> = ({
  value = -1,
  insuline = 0.0,
  glucoseRange,
}) => {
  const theme = useTheme();

  return (
    <Container>
      <Card>
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <Text className="mb-2" color="softDark">
              Glucosio
            </Text>
            <Text className="position-absolute" color="dark" size="small" style={{ top: '72px' }}>
              {`${value} mg/dl`}
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 165.208 165.208"
            >
              <g transform="translate(4.5 4.5)">
                <path
                  d="M-328.386,88.582a78.1,78.1,0,0,0-78.1,78.1,77.742,77.742,0,0,0,11.4,40.647v0c.558.914,1.141,1.812,1.736,2.7q.222.333.451.664c.612.9,1.236,1.782,1.884,2.65l.057.079c.665.889,1.354,1.757,2.055,2.616q.255.312.513.622c.708.848,1.427,1.687,2.17,2.5.042.047.086.092.129.138.71.779,1.441,1.54,2.183,2.29.187.189.374.378.563.565.779.773,1.572,1.534,2.383,2.273.095.086.192.167.285.253q1.117,1.006,2.272,1.967c.2.167.4.335.6.5q1.27,1.038,2.581,2.028c.138.1.28.2.418.305q1.172.868,2.377,1.69c.207.142.413.286.622.425q1.364.914,2.765,1.774c.176.106.355.209.531.314q1.236.743,2.5,1.438c.207.114.411.23.618.342q1.452.782,2.939,1.5c.207.1.418.2.626.3q1.307.621,2.639,1.193c.2.086.395.175.594.258q1.531.644,3.1,1.221c.234.086.469.169.7.253q1.389.5,2.8.948c.18.057.356.117.536.174q1.605.494,3.24.92c.255.068.512.13.77.194.992.249,1.992.483,3,.694.143.029.285.064.428.092,1.116.227,2.244.425,3.378.6.271.042.544.082.816.123,1.075.157,2.156.3,3.245.413.089.009.176.022.266.031,1.16.116,2.327.2,3.5.266.284.017.568.029.853.042,1.187.054,2.377.091,3.576.091a78.1,78.1,0,0,0,78.1-78.1A78.1,78.1,0,0,0-328.386,88.582Z"
                  transform="translate(406.49 -88.582)"
                  fill="none"
                  stroke={
                    (value < glucoseRange.mid[0] && theme.colors.secondary) ||
                    (value > glucoseRange.mid[1] && 'red') ||
                    theme.colors.primary
                  }
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="9"
                />
              </g>
            </svg>
          </Col>
          <Col className="d-flex flex-column align-items-center justify-content-between">
            <Image src={BloodIcon} width="20px" />
            <Text
              className="position-absolute"
              color="dark"
              size="smallest"
              style={{ top: '95px' }}
            >
              {`${insuline} Insulina`}
            </Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 71.359 71.359"
            >
              <g transform="translate(1 1)">
                <path
                  d="M-371.81,88.582a34.68,34.68,0,0,0-34.68,34.68,34.519,34.519,0,0,0,5.062,18.048v0c.248.406.506.8.771,1.2q.1.148.2.295c.272.4.549.791.836,1.177l.026.035c.3.395.6.78.913,1.161q.113.139.228.276c.314.377.633.749.964,1.112l.057.061c.315.346.64.684.969,1.017.083.084.166.168.25.251.346.343.7.681,1.058,1.009.042.038.085.074.126.112q.5.447,1.009.873l.267.222q.564.461,1.146.9c.061.046.124.09.185.136q.521.385,1.055.75c.092.063.183.127.276.189q.606.406,1.228.788l.236.14q.549.33,1.11.639l.274.152q.645.347,1.3.668c.092.045.185.087.278.131q.58.276,1.172.53c.088.038.175.078.264.115q.68.286,1.375.542l.312.112q.617.221,1.244.421l.238.077q.713.219,1.439.408c.113.03.227.058.342.086.441.111.885.214,1.332.308.063.013.126.028.19.041.5.1,1,.189,1.5.268l.362.054c.478.07.957.133,1.441.183l.118.014c.515.052,1.033.089,1.554.118.126.007.252.013.379.019.527.024,1.055.04,1.588.04a34.68,34.68,0,0,0,34.68-34.68A34.68,34.68,0,0,0-371.81,88.582Z"
                  transform="translate(406.49 -88.582)"
                  fill="none"
                  stroke="#8fd3e6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default GlucoseMeter;
