import React, { FunctionComponent } from 'react';
import { Row } from 'react-bootstrap';
import Button from '../../../../components/Button';
import { Text } from '../../../../components/styled';

export interface UserLoggedProps {
  name?: string | null;
  onLogout?: () => void;
}

const UserLogged: FunctionComponent<UserLoggedProps> = ({
  name = 'Dr. Richard Blue',
  onLogout = () => {},
}) => {
  return (
    <article>
      <Row>
        <Text color="softDark">Bentornato &nbsp;</Text>
        <strong>
          <Text color="softDark">{`Dr. ${name}`}</Text>
        </strong>
      </Row>
      <Row className="d-flex justify-content-end">
        <Button
          className="mr-0 pr-0"
          name="logout-button"
          btnStyle="link"
          text="Logout"
          onClick={onLogout}
        />
      </Row>
    </article>
  );
};

export default UserLogged;
