import { FunctionComponent } from 'react';
import { Row, RowProps } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.article`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0 15px 0;
`;

// export const StyledRow: FunctionComponent<RowProps> = styled(Row)`

// ` as any;
