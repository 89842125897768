export const theme = {
  borders: {
    round: '30px',
    circle: '50px',
  },
  colors: {
    primary: '#2F73B8',
    secondary: '#43BCD8',
    light: 'white',
    dark: 'black',
    softDark: 'grey',
    ghost: '#F0F3F4',
    danger: 'red',
  },
  fonts: {
    family: 'Montserrat',
    size: {
      base: '1rem',
      small: '0.8rem',
      smallest: '0.6rem',
      medium: '1.1rem',
      big: '1.2rem',
    },
    weight: {
      base: 400,
      medium: 500,
      bold: 600,
    },
  },
};
