import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SagaException } from '../../definitions/interfaces';
import { AuthState as AuthState, User } from './types';

const initialState: AuthState = {
  email: null,
  user: null,
  signinSuccessful: false,
  isFetching: false,
  isError: false,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',

  initialState,

  reducers: {
    setEmail: (state: AuthState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    signupRequest: (
      state: AuthState,
      action: PayloadAction<{
        username: string;
        email: string;
        password: string;
        nationalDoctorCode: string;
      }>
    ) => {
      state.signinSuccessful = false;
      state.isFetching = true;
      state.isError = false;
      state.error = null;
    },
    signupSuccess: (state: AuthState, action: PayloadAction<User>) => {
      // state.user = {};
      state.signinSuccessful = true;
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    signupFailure: (state: AuthState, action: PayloadAction<SagaException>) => {
      state.isError = true;
      state.signinSuccessful = false;
      state.error = action.payload;
      state.isFetching = false;
    },
    loginRequest: (
      state: AuthState,
      action: PayloadAction<{ email: string; password: string }>
    ) => {
      state.isFetching = true;
      state.isError = false;
      state.error = null;
    },
    loginSuccess: (state: AuthState, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    loginFailure: (state: AuthState, action: PayloadAction<SagaException>) => {
      state.isError = true;
      state.error = action.payload;
      state.isFetching = false;
    },
    logoutRequest: (state: AuthState, action: AnyAction) => {
      state.isFetching = true;
      state.isError = false;
      state.error = null;
    },
    logoutSuccess: (state: AuthState, action: AnyAction) => {
      state.user = null;
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    logoutFailure: (state: AuthState, action: PayloadAction<SagaException>) => {
      state.isError = true;
      state.error = action.payload;
      state.isFetching = false;
    },
    passwordRecoveryRequest: (state: AuthState, action: PayloadAction<{ email: string }>) => {
      state.isFetching = true;
      state.isError = false;
      state.error = null;
    },
    passwordRecoverySuccess: (state: AuthState, action: AnyAction) => {
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    passwordRecoveryFailure: (state: AuthState, action: PayloadAction<SagaException>) => {
      state.isError = true;
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export const actions = authSlice.actions;
export const reducer = authSlice.reducer;
