import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Image, Paragraph, ParagraphTitle, Text } from '../../../../components/styled';
import { GlucoseRange } from '../../../../definitions/glucoseRange';
import { Measurement } from '../../types';
import {
  Container,
  YearContainer,
  MonthContainer,
  DayContainer,
  MeasurementContainer,
} from './styled';
import ArrowIcon from '../../../../assets/arrow.png';
import BloodIcon from '../../../../assets/blood.png';
import FoodIcon from '../../../../assets/apple.png';
import SportIcon from '../../../../assets/runner.png';
import MedicineIcon from '../../../../assets/pill.png';
import WeightIcon from '../../../../assets/weight.png';
import moment from 'moment';
import { groupBy, mapValues, map, toPairs, head, orderBy, keysIn } from 'lodash';
import { Card } from '../../../../components/styled/Card';

export interface LogBookProps {
  glucoseRange: GlucoseRange;
  measurements: Measurement[];
}

const LogBook: FunctionComponent<LogBookProps> = ({ glucoseRange, measurements }) => {
  const [explodedYear, setExplodedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDay, setSelectedDay] = useState('');

  useEffect(() => {
    // Get most recent measurement
    const measurement = head(orderBy(measurements, (ms) => ms.dateTime, 'desc'));
    setExplodedYear(moment(measurement?.dateTime).format('YYYY'));
    setSelectedMonth(moment(measurement?.dateTime).format('MMMM'));
    setSelectedDay(moment(measurement?.dateTime).format('DD'));
  }, []);

  // Example 2019 -> 07 -> 21 -> Measurement
  const history = useMemo(
    () =>
      mapValues(
        mapValues(groupBy(measurements, (m) => moment(m.dateTime).format('YYYY'))),
        (years) =>
          mapValues(
            groupBy(years, (m) => moment(m.dateTime).format('MMMM')),
            (months) => groupBy(months, (m) => moment(m.dateTime).format('DD'))
          )
      ),
    [measurements]
  );

  useEffect(() => {
    if (explodedYear) {
      if (selectedMonth == '') {
        const firstMonth = head(keysIn(history[explodedYear])) || '';
        setSelectedMonth(firstMonth);
      }

      if (selectedDay == '') {
        const firstDay = head(keysIn(history[explodedYear][selectedMonth])) || '';
        setSelectedDay(firstDay);
      }
    }
  }, [explodedYear, selectedMonth, selectedDay]);

  const yearClickHandler = useCallback((yearName) => {
    setSelectedDay('');
    setSelectedMonth('');
    setExplodedYear(yearName);
  }, []);

  const renderDay = useMemo(() => {
    return (
      <Card>
        <DayContainer>
          <Row className="ml-2 mb-3 p-3">
            <Text className="mx-2" weight="bold" size="big" color="secondary">
              {selectedDay}
            </Text>
            <Text className="mr-2" weight="bold" size="big" color="secondary">
              {selectedMonth}
            </Text>
            <Text weight="bold" size="big" color="primary">
              {explodedYear}
            </Text>
          </Row>
        </DayContainer>
        {history[explodedYear] &&
          history[explodedYear][selectedMonth] &&
          map(history[explodedYear][selectedMonth][selectedDay], (ms, i) => (
            <MeasurementContainer className="px-3 py-4 mx-3 align-items-center">
              <Image
                className="mr-3"
                src={
                  (ms.type === 'blood' && BloodIcon) ||
                  (ms.type === 'food' && FoodIcon) ||
                  (ms.type === 'sport' && SportIcon) ||
                  (ms.type === 'medicine' && MedicineIcon) ||
                  (ms.type === 'weight' && WeightIcon) ||
                  BloodIcon
                }
                width="20px"
              />
              {ms.type !== 'blood' && (
                <Text className="mr-3" weight="medium" size="base" color="primary">
                  {ms.value}
                </Text>
              )}
              <Text className="mr-3" weight="medium" size="base" color="primary">
                {`${
                  (ms.type === 'blood' && ms.value) || (ms.type !== 'weight' && ms.quantity) || ''
                } ${ms.unit}`}
              </Text>
              <Text weight="base" size="base" color="secondary">
                {`${ms.tag || 'Nessuna etichetta'}`}
              </Text>
              <Text className="ml-auto" weight="base" size="base" color="softDark">
                {moment(ms.dateTime).format('hh:mm')}
              </Text>
            </MeasurementContainer>
          ))}
        <section className="mt-3">
          {history[explodedYear] &&
            history[explodedYear][selectedMonth] &&
            map(toPairs(history[explodedYear][selectedMonth]), ([dayName, ms], i, pairs) => {
              if (dayName !== selectedDay)
                return (
                  <DayContainer
                    key={`${dayName}.${i}`}
                    className="p-3 align-items-center"
                    onClick={() => setSelectedDay(dayName)}
                  >
                    <Image className="mr-3" src={ArrowIcon} width="10px" height="15px" />
                    <Text weight="base" size="base" color="secondary">
                      {moment(head(ms)?.dateTime).format('dddd DD MMMM')}
                    </Text>
                  </DayContainer>
                );
              else return null;
            })}
        </section>
      </Card>
    );
  }, [explodedYear, selectedMonth, selectedDay]);

  return (
    <Container>
      <Row>
        <Col md={3}>
          <ParagraphTitle className="mb-3" color="primary">
            LogBook
          </ParagraphTitle>
          {map(history, (months, yearName, i) => (
            <>
              <YearContainer
                className="align-items-center my-3"
                key={`${yearName}.${i}`}
                onClick={() => yearClickHandler(yearName)}
              >
                <Image
                  className="mr-3"
                  src={ArrowIcon}
                  width="15px"
                  rotateDeg={(explodedYear === yearName && 90) || 360}
                />
                <Text weight="bold" size="big" color="primary">
                  {yearName}
                </Text>
              </YearContainer>
              {map(months, (days, monthName) => (
                <MonthContainer
                  show={explodedYear === yearName}
                  className="align-items-center my-3"
                  onClick={() => setSelectedMonth(monthName)}
                  key={`${monthName}.${i}`}
                >
                  <Image className="mr-3" src={ArrowIcon} width="15px" />
                  <Text
                    weight={(selectedMonth === monthName && 'bold') || 'base'}
                    size="big"
                    color="secondary"
                  >
                    {monthName}
                  </Text>
                </MonthContainer>
              ))}
            </>
          )).reverse()}
        </Col>
        <Col md={6}>{renderDay}</Col>
      </Row>
    </Container>
  );
};

export default LogBook;
