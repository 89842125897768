import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Page from '../../../components/Page';
import { BasicLayout } from '../../../components/layouts/BasicLayout';
import { Col, Container, Row } from 'react-bootstrap';
import { Paragraph, Title, ParagraphTitle, Highlight, Image } from '../../../components/styled';
import StethoscopeImage from '../../../assets/stethoscope.png';
import Loader from '../../../components/Loader';
import { selectors as loginSelectors } from '../../Auth';
import { actions as patientsAction, selectors as patientsSelectors } from '../../Patients';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import SignupModal from '../../Auth/components/SignupModal';
import LoginModal from '../../Auth/components/LoginModal';
import PatientList from '../components/PatientList';
import Search from '../../../components/Search';
import GlucoseLegend from '../components/GlucoseLegend';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useRef } from 'react';

const PatientsView: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const discovery = useRef<any>();
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const user = useSelector(loginSelectors.selectUser);
  const isFetching = useSelector(patientsSelectors.selectIsFetching);
  const isEmpty = useSelector(patientsSelectors.selectIsEmpty);
  const patients = useSelector(patientsSelectors.selectPatients);

  const patientDetailsHandler = useCallback(
    (patientId: string) => history.push(`/patients/${patientId}`),
    []
  );

  useEffect(() => {
    if (user) dispatch(patientsAction.patientsRequest());
  }, []);

  useEffect(() => {
    if (user)
      discovery.current = setInterval(() => dispatch(patientsAction.patientsRequest()), 15000);
  }, [user]);

  useEffect(() => {
    return () => clearInterval(discovery.current);
  }, []);

  if (isFetching)
    return (
      <>
        <Loader />
      </>
    );

  if (!user) return <Redirect to="/" />;

  return (
    <BasicLayout bgWhite>
      <Page container style={{ minHeight: '46vh' }}>
        <Row className="mt-4 align-items-center justify-content-center">
          <Col className="d-inline-flex align-items-center">
            <Image className="mr-2" src={StethoscopeImage} width="50px" opacity={0.7} />
            <ParagraphTitle color="primary">My Doctor</ParagraphTitle>
          </Col>
          <Col>
            <Search onSearch={(x) => console.log('search', x)} />
          </Col>
          <Col>
            <Paragraph
              className="text-center mb-0"
              color="primary"
            >{`Doctor Code: ${user?.code}`}</Paragraph>
          </Col>
        </Row>
        <Container className="mt-5 d-inline-flex justify-content-between">
          <Title color="primary" shadow={false}>
            Pazienti
          </Title>
          <GlucoseLegend />
        </Container>
        <PatientList patients={patients} onPatientClick={patientDetailsHandler} />
      </Page>
      <SignupModal show={showSignupModal} onClose={() => setShowSignupModal(!showSignupModal)} />
      <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(!showLoginModal)} />
    </BasicLayout>
  );
};

export default PatientsView;
