import React, { FunctionComponent, HTMLProps } from 'react';
import { DefaultTheme } from 'styled-components';
import { BtnStyle, StyledButton } from './styled';

export interface ButtonProps
  extends Omit<HTMLProps<HTMLButtonElement>, 'text' | 'ref' | 'type' | 'as'> {
  name: string;
  text: string;
  btnStyle: BtnStyle;
  color?: keyof DefaultTheme['colors'];
}

const Button: FunctionComponent<ButtonProps> = ({
  className,
  name = 'button',
  text = 'Click Me',
  btnStyle = 'link',
  onClick = () => {},
  ...props
}) => {
  return (
    <StyledButton
      className={className}
      name={name}
      type="button"
      onClick={onClick}
      btnStyle={btnStyle}
      {...props}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
