import styled, { css, DefaultTheme } from 'styled-components';

export const Text = styled.span<{
  color?: keyof DefaultTheme['colors'];
  size?: keyof DefaultTheme['fonts']['size'];
  weight?: keyof DefaultTheme['fonts']['weight'];
}>`
  color: ${({ color, theme }) => (color && theme.colors[color]) || 'white'};
  text-transform: capitalize;

  ${({ weight, theme }) =>
    weight &&
    css`
      font-weight: ${theme.fonts.weight[weight]};
    `}

  ${({ size, theme }) =>
    size &&
    css`
      font-size: ${theme.fonts.size[size]};
    `}
`;
