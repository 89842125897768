import React from 'react';
import LoaderSpinner from 'react-loader-spinner';
import { withTheme, ThemeProps, DefaultTheme } from 'styled-components';
import { LoaderContainer, LoaderText } from './styled';
import { Col, Row } from 'react-bootstrap';

interface LoaderProps extends ThemeProps<DefaultTheme> {}

const Loader: React.FunctionComponent<LoaderProps> = ({ theme }): JSX.Element => {
  return (
    <LoaderContainer>
      <Row>
        <Col className="d-flex justify-content-center">
          <LoaderSpinner type="Rings" color={theme.colors.primary} />
        </Col>
      </Row>
    </LoaderContainer>
  );
};

export default withTheme(Loader);
