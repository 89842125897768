import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  /* width: 96.5%; */
  width: 100%;
  height: 100%;
  top: 0;
  background-color: ${({ theme }) => theme.colors.primary};
  opacity: 0.5;
  filter: contrast(2) brightness(1.5);
`;
