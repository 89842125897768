import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SagaException } from '../../definitions/interfaces';
import { DoctorCode, PatiensState as PatientsState, PatientType } from './types';

const initialState: PatientsState = {
  patients: null,
  patient: null,
  isFetching: false,
  isError: false,
  error: null,
};

const patientsSlice = createSlice({
  name: 'patients',

  initialState,

  reducers: {
    patientsRequest: (state: PatientsState, action: AnyAction) => {
      state.patients = null;
      state.patient = null;
      state.isFetching = true;
      state.isError = false;
      state.error = null;
    },
    patientsSuccess: (state: PatientsState, action: PayloadAction<PatientType[]>) => {
      state.patients = action.payload;
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    patientsFailure: (state: PatientsState, action: PayloadAction<SagaException>) => {
      state.isError = true;
      state.error = action.payload;
      state.isFetching = false;
    },
    patientDetailsRequest: (state: PatientsState, action: PayloadAction<{ patientId: string }>) => {
      state.isFetching = true;
      state.isError = false;
      state.error = null;
    },
    patientDetailsSuccess: (state: PatientsState, action: PayloadAction<PatientType>) => {
      state.patient = action.payload;
      state.isFetching = false;
      state.isError = false;
      state.error = null;
    },
    patientDetailsFailure: (state: PatientsState, action: PayloadAction<SagaException>) => {
      state.isError = true;
      state.error = action.payload;
      state.isFetching = false;
    },
  },
});

export const actions = patientsSlice.actions;
export const reducer = patientsSlice.reducer;
