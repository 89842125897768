import styled, { css, DefaultTheme } from 'styled-components';

export const Title = styled.h1<{
  bold?: boolean;
  color?: keyof DefaultTheme['colors'];
  shadow?: boolean;
}>`
  color: ${({ color, theme }) => (color && theme.colors[color]) || 'white'};
  ${({ shadow = true }) =>
    (shadow &&
      css`
        text-shadow: 0px 1px 6px grey;
      `) ||
    css`
      text-shadow: 0px 1px 2px grey;
    `}

  font-weight: ${({ bold }) => (bold && 'bold') || 'normal'};
`;

export const Highlight = styled.p<{ big?: boolean }>`
  color: ${({ theme }) => theme.colors.secondary};
  font-size: ${({ big }) => (big && '3rem') || '2rem'};
  text-shadow: 1px 2px 1px lightgray;
`;
