import React, { FunctionComponent, useState } from 'react';
import { StyledModalBody, StyledModalBodyProps } from './styled';
import { Modal as BSModal, Row } from 'react-bootstrap';
import NoImage from '../../assets/No.png';

export interface ModalProps extends StyledModalBodyProps {
  className?: string;
  show?: boolean;
  onClose?: () => void;
}

const Modal: FunctionComponent<ModalProps> = ({
  children,
  className,
  gradient,
  show,
  onClose = () => {},
  ...props
}) => {
  return (
    <BSModal show={show} centered>
      <StyledModalBody gradient={gradient} {...props}>
        <a
          className="position-absolute"
          href=""
          onClick={onClose}
          style={{ top: '40px', right: '45px' }}
        >
          <img src={NoImage} width="15px" />
        </a>
        {children}
      </StyledModalBody>
    </BSModal>
  );
};

export default Modal;
