import { takeEvery, put, select } from 'redux-saga/effects';
import { actions, PatientType } from '.';
import firebase from 'firebase/app';
import { app } from '../../contexts/FirebaseContext';
import { Measurement } from './types';
import { RootState } from '../../store';

function* patientsSaga({ payload }: ReturnType<typeof actions.patientsRequest>) {
  try {
    const doctorCode: string = yield select((rootState: RootState) => rootState.auth.user?.code);

    const response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield app.patients(
      doctorCode
    );

    // yield app.auth.currentUser?.updateProfile({
    //   displayName: '',
    // });

    const patients = response.docs.map((p) => <PatientType>{ ...p.data(), id: p.id });

    if (patients.length > 0) {
      for (const patient of patients) {
        const lastMeasurement: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> = (yield app.lastMeasurement(
          patient.userId
        )).docs[0];

        if (patient.birthdate)
          patient.birthdate = (<firebase.firestore.Timestamp>(<unknown>patient.birthdate)).toDate();

        if (lastMeasurement)
          patient.lastMeasurement = {
            ...(lastMeasurement.data() as Measurement),
            id: lastMeasurement.id,
            dateTime: (<firebase.firestore.Timestamp>(
              (<unknown>(lastMeasurement.data() as Measurement).dateTime)
            )).toDate(),
          };
      }
    }

    yield put(actions.patientsSuccess(patients));
  } catch (exception) {
    yield put(actions.patientsFailure(exception));
  }
}

function* patientDetailsSaga({
  payload: { patientId },
}: ReturnType<typeof actions.patientDetailsRequest>) {
  try {
    const doctorCode: string = yield select((rootState: RootState) => rootState.auth.user?.code);
    const patients: PatientType[] = yield select(
      (rootState: RootState) => rootState.patients.patients
    );

    let patientIndex = -1;

    let patient = patients.find((patient, i) => {
      if (patient.id === patientId) {
        patientIndex = i;
        return true;
      }
    });

    if (patient) {
      const response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield app.measurements(
        patient.userId
      );

      const measurements = response.docs.map<Measurement>((doc) => ({
        ...(doc.data() as Measurement),
        id: doc.id,
        dateTime: (<firebase.firestore.Timestamp>(
          (<unknown>(doc.data() as Measurement).dateTime)
        )).toDate(),
      }));

      patient = { ...patient, measurements };
      yield put(actions.patientDetailsSuccess(patient));
    }
  } catch (exception) {
    yield put(actions.patientDetailsFailure(exception));
  }
}

export default [
  takeEvery(actions.patientsRequest, patientsSaga),
  takeEvery(actions.patientDetailsRequest, patientDetailsSaga),
];
