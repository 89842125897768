import { FunctionComponent } from 'react';
import { Row, RowProps } from 'react-bootstrap';
import styled from 'styled-components';

export const Container = styled.article`
  margin: 50px 0;
  min-width: 30%;
  width: 100%;
`;

export const MeasurementContainer: FunctionComponent<RowProps> = styled(Row)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.ghost};
` as any;

export const DayContainer: FunctionComponent<RowProps & { onClick?: () => void }> = styled(Row)`
  cursor: pointer;
` as any;

export const YearContainer: FunctionComponent<RowProps & { onClick?: () => void }> = styled(Row)`
  cursor: pointer;
` as any;

export const MonthContainer: FunctionComponent<
  RowProps & { show?: boolean; onClick?: () => void }
> = styled(Row)`
  cursor: pointer;
  margin-left: 20px;
  display: ${({ show }) => (show && 'flex') || 'none'};
` as any;
