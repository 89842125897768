import styled, { css } from 'styled-components';

export const Container = styled.article<{ disabled?: boolean }>`
  width: 300px;

  ${({ disabled }) =>
    disabled &&
    css`
      filter: grayscale(1) opacity(0.5);
    `}
`;
