import React, { FunctionComponent } from 'react';
import { StyledFooter, StyledIcon } from './styled';
import { Col, Container, Row } from 'react-bootstrap';
import PhoneIcon from '../../assets/phone.png';
import MsgIcon from '../../assets/message.png';
import InstIcon from '../../assets/instagram.png';
import FbIcon from '../../assets/facebook.png';
import YtIcon from '../../assets/youtube.png';

const Footer: FunctionComponent = () => {
  return (
    <StyledFooter>
      <Container className="h-100">
        <Row className="h-100">
          <Col md={4} className="align-self-center">
            <Row className="justify-content-start align-items-center pb-2">Contatti: </Row>
            <Row className="justify-content-between align-items-center">
              <Row className="align-items-center">
                <StyledIcon src={PhoneIcon} />
                0984-465633
              </Row>
              <Row className="align-items-center">
                <StyledIcon src={MsgIcon} />
                info@farsudsrl.it
              </Row>
            </Row>
          </Col>
          <Col className="align-self-center">
            <Row className="justify-content-end align-items-center">
              <StyledIcon src={InstIcon} />
              <StyledIcon src={FbIcon} width={13} height={22} />
              <StyledIcon src={YtIcon} height={20} />
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
