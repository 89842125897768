import styled, { css } from 'styled-components';

export const Image = styled.img<{
  toWhite?: boolean;
  toBlack?: boolean;
  opacity?: number;
  rotateDeg?: number;
}>`
  ${({ toWhite }) =>
    toWhite &&
    css`
      filter: brightness(0) invert(1);
    `}

  ${({ toBlack }) =>
    toBlack &&
    css`
      filter: brightness(0);
    `}

    ${({ opacity }) =>
    opacity &&
    css`
      opacity: ${opacity};
    `}

    ${({ rotateDeg }) =>
    rotateDeg &&
    css`
      transform: rotate(${rotateDeg}deg);
    `}
`;
