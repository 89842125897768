import styled, { css, DefaultTheme } from 'styled-components';

export const Paragraph = styled.p<{ color?: keyof DefaultTheme['colors']; width?: string }>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};
  color: ${({ color, theme }) => (color && theme.colors[color]) || 'white'};
`;

export const ParagraphTitle = styled.h4<{ color?: keyof DefaultTheme['colors']; width?: string }>`
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  color: ${({ color, theme }) => (color && theme.colors[color]) || 'white'};
`;
