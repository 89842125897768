import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }

  body {
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.size.base};
    color: white;
  }

  .modal-content {
    border: none;
    border-radius: ${({ theme }) => theme.borders.circle};
  }
`;

export default GlobalStyle;
