import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Image, ParagraphTitle, Text } from '../styled';
import { Container } from './styled';
import moment from 'moment';

export interface UserInfoPanelProps {
  name?: string;
  country?: string;
  birthdate?: Date;
  gender?: 'male' | 'female';
  photo?: string;
}

const UserInfoPanel: FunctionComponent<UserInfoPanelProps> = ({
  name = '?',
  country = '?',
  birthdate = '?',
  gender = '?',
  photo = '',
}) => {
  return (
    <Container>
      <Image className="mb-3" src={photo} width="100px" />
      <ParagraphTitle color="primary">{name}</ParagraphTitle>
      <Row style={{ width: '300px' }}>
        <Col className="d-flex flex-column align-items-center">
          <Text color="softDark" size="small">
            Nome:
          </Text>
          <Text color="softDark" size="small">
            {name}
          </Text>
        </Col>
        <Col className="d-flex flex-column align-items-center">
          <Text color="softDark" size="small">
            Genere:
          </Text>
          <Text color="softDark" size="small">
            {(gender === 'male' && 'Uomo') || (gender === 'female' && 'Donna') || 'Non fornito'}
          </Text>
        </Col>
      </Row>
      <Row className="mt-2" style={{ width: '300px' }}>
        <Col className="d-flex flex-column align-items-center">
          <Text color="softDark" size="small">
            Nazione:
          </Text>
          <Text color="softDark" size="small">
            {(country !== '' && country) || 'Non fornito'}
          </Text>
        </Col>
        <Col className="d-flex flex-column align-items-center">
          <Text color="softDark" size="small">
            Data di nascita:
          </Text>
          <Text color="softDark" size="small">
            {(birthdate && moment(birthdate).format('DD/MM/yyyy')) || 'Non fornito'}
          </Text>
        </Col>
      </Row>
    </Container>
  );
};

export default UserInfoPanel;
