import styled from 'styled-components';

export const StyledPage = styled.article<{ bgImage: string }>`
  /* height: 100vh; */
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  /* background-size: 1920px 1200px; */
  /* background-size: 1790px 1110px; */
  background-size: 110% 1250px;
`;
