import styled from 'styled-components';

export const StyledTableItem = styled.tr`
  &:hover {
    cursor: pointer;
  }
`;

export const StyledTableField = styled.td`
  text-align: center;
  vertical-align: middle !important;
`;
