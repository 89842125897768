import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Page from '../../../components/Page';
import { BasicLayout } from '../../../components/layouts/BasicLayout';
import { Col, Container, Row } from 'react-bootstrap';
import { Paragraph, Title, ParagraphTitle, Highlight, Image } from '../../../components/styled';
import BannerImage from '../../../assets/banner.png';
import GooglePlayStoreImage from '../../../assets/google-play-store.png';
import AppStoreImage from '../../../assets/app-store.png';
import HWStoreImage from '../../../assets/huawei-store.png';
import ProductImage from '../../../assets/product.png';
import BluetoothImage from '../../../assets/bluetooth.png';
import StripesImage from '../../../assets/stripes.png';
import StethoscopeImage from '../../../assets/stethoscope.png';
import BgSectionImage from '../../../assets/bg-section.png';
import Loader from '../../../components/Loader';
import { actions, selectors as loginSelectors } from '../../Auth';
import { useDispatch, useSelector } from 'react-redux';
import Section from '../../../components/Section';
import Button from '../../../components/Button';
import SignupModal from '../../Auth/components/SignupModal';
import LoginModal from '../../Auth/components/LoginModal';
import Modal from '../../../components/Modal';
import { useAppDispatch } from '../../../store';

const HomeView: FunctionComponent<RouteComponentProps> = ({ history, location }) => {
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showSignupOKModal, setShowSignupOKModal] = useState(false);
  const [showSignupEmailAlreadyInUseModal, setShowSignupEmailAlreadyInUseModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showUserNotFoundModal, setShowUserNotFoundModal] = useState(false);
  const [showPasswordRecoveryModal, setShowPasswordRecoveryModal] = useState(false);
  const [showUserExistModal, setShowUserExistModal] = useState(false);
  const isFetching = useSelector(loginSelectors.selectIsFetching);
  const email = useSelector(loginSelectors.selectEmail);
  const user = useSelector(loginSelectors.selectUser);
  const isLogged = useSelector(loginSelectors.selectIsLogged);
  const signinSuccessful = useSelector(loginSelectors.selectSigninSuccessful);
  const isError = useSelector(loginSelectors.selectIsError);
  const error = useSelector(loginSelectors.selectError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogged) {
      history.push('/patients');
    }
  }, [isLogged]);

  if (isFetching)
    return (
      <>
        <Loader />
      </>
    );

  return (
    <BasicLayout>
      {/* <Page style={{ height: '2000px' }}> */}
      <Page>
        <Container>
          <Row>
            <Col md={6}>
              <Title>iControl G</Title>
              <Title bold style={{ marginTop: '-20px' }}>
                app
              </Title>
              <ParagraphTitle width="320px">L’automonitoraggio della glicemia</ParagraphTitle>
              <Paragraph width="350px">
                Ridurre il rischio di ipoglicemie e di complicanze micro e macro-angiopatiche
                associate al diabete mellito.
              </Paragraph>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-start align-items-center">
              <Paragraph color="softDark" width="350px">
                Ridurre il rischio di ipoglicemie e di complicanze micro e macro-angiopatiche
                associate al diabete mellito.
              </Paragraph>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <a href="https://play.google.com/store/apps/details?id=it.nexev.icontrolg">
                <img className="m-1" src={GooglePlayStoreImage} width="136px" />
                <a href="https://apps.apple.com/it/app/icontrol-g/id1558995259">
                  <img className="m-1" src={AppStoreImage} width="136px" />
                  <a href="https://appgallery.huawei.com/#/app/C103878347">
                    <img className="m-1" src={HWStoreImage} width="136px" />
                  </a>
                </a>
              </a>
            </Col>
            <Col>
              <img src={BannerImage} width="500px" style={{ marginTop: '-150px' }} />
            </Col>
          </Row>

          <Row>
            <Col md={3} className="d-flex justify-content-center align-items-center">
              <img src={ProductImage} width="400px" />
            </Col>
            <Col
              md={{ span: 3, offset: 4 }}
              className="d-flex justify-content-start align-items-center"
            >
              <Col>
                <section className="d-inline-flex align-items-center">
                  <Highlight big className="mr-4">
                    bluetooth
                  </Highlight>
                  <img src={BluetoothImage} className="mb-5" width="80px" />
                </section>
                <Paragraph color="softDark" width="350px" style={{ marginTop: '-30px' }}>
                  {` Il tuo iControl G si sincronizza automaticamente con l’app mobile e può condividere 
                  le misurazioni con il tuo medico.`}
                </Paragraph>
                <img src={StripesImage} width="200px" />
              </Col>
            </Col>
            <Highlight big style={{ marginTop: '-50px' }}>
              iControl G
            </Highlight>
          </Row>
        </Container>
        <Section image={BgSectionImage} imagePosition="right" gradient overlay>
          <Row className="d-flex align-items-center justify-content-center">
            <Col md={6}>
              <ParagraphTitle>Modulo di registrazione</ParagraphTitle>
              <Paragraph>
                {`I medici di base o specialisti possono registrarsi alla piattaforma iControl G per 
                associare i propri pazienti e visualizzare i loro dati registrati nelle schede pazienti.`}
              </Paragraph>
              <section style={{ marginTop: '50px' }}>
                <Row className="justify-content-center">
                  <Button
                    name="mdl-button"
                    text="Affiliazione"
                    btnStyle="filled-inverted"
                    onClick={() => setShowSignupModal(!showSignupModal)}
                  />
                </Row>
                <Row className="justify-content-center mt-4">
                  <Button
                    name="mdl-button"
                    text="Login"
                    btnStyle="link-outline"
                    onClick={() => setShowLoginModal(!showLoginModal)}
                  />
                </Row>
              </section>
            </Col>
            <Col md={2}>
              <Image
                src={StethoscopeImage}
                toWhite
                width="100px"
                style={{ marginBottom: '6rem' }}
              />
            </Col>
          </Row>
        </Section>
        <Section className="py-5">
          <Col md={6} className="d-flex flex-column justify-content-center align-items-center">
            <ParagraphTitle color="primary" width="350px">
              Download the I Control G manuals
            </ParagraphTitle>
            <Paragraph color="secondary" width="350px">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eget ligula eu lectus
              lobortis condimentum. Aliquam nonummy auctor massa. Pellentesque habitant morbi
              tristique
            </Paragraph>
          </Col>
          <Col md={6}></Col>
        </Section>
      </Page>
      <SignupModal
        show={showSignupModal && !signinSuccessful && !isError}
        onClose={() => setShowSignupModal(!showSignupModal)}
      />
      <LoginModal show={showLoginModal} onClose={() => setShowLoginModal(!showLoginModal)} />
      <Modal
        gradient
        show={error?.code == 'auth/user-not-found'}
        onClose={() => setShowUserNotFoundModal(!showUserNotFoundModal)}
      >
        <Row style={{ width: '65%' }}>
          <h4>Account non trovato</h4>
          <section className="mt-4">
            <a
              href=""
              style={{ color: 'lightblue' }}
              onClick={(event) => {
                event.preventDefault();
                setShowSignupModal(!showSignupModal);
              }}
            >
              <p>Clicca qui per registrarti</p>
            </a>
          </section>
        </Row>
      </Modal>
      <Modal
        gradient
        show={error?.code == 'auth/wrong-password'}
        onClose={() => setShowUserNotFoundModal(!showUserNotFoundModal)}
      >
        <Row style={{ width: '65%' }}>
          <h4>Password errata</h4>
          <section className="mt-4">
            <a
              href=""
              style={{ color: 'lightblue' }}
              onClick={(event) => {
                event.preventDefault();
                setShowPasswordRecoveryModal(!showPasswordRecoveryModal);
                if (email) dispatch(actions.passwordRecoveryRequest({ email }));
              }}
            >
              Clicca qui per recuperare la password
            </a>
          </section>
        </Row>
      </Modal>
      <Modal
        gradient
        show={error?.code == 'auth/email-already-in-use'}
        onClose={() => setShowSignupEmailAlreadyInUseModal(!showSignupEmailAlreadyInUseModal)}
      >
        <Row style={{ width: '65%' }}>
          <h4>Account già in uso</h4>
          <section className="mt-4">
            Account già in uso, cambia email per poter proseguire nella registrazione
          </section>
        </Row>
      </Modal>
      <Modal
        gradient
        show={signinSuccessful}
        onClose={() => setShowSignupOKModal(!showSignupOKModal)}
      >
        <Row style={{ width: '65%' }}>
          <h4>Registrazione effettuata</h4>
          <section className="mt-4">
            {`Abbiamo provveduto a inviarti una mail con un link per attivare l'account.`}
          </section>
        </Row>
      </Modal>
      <Modal
        gradient
        show={showPasswordRecoveryModal}
        onClose={() => setShowPasswordRecoveryModal(!showPasswordRecoveryModal)}
      >
        <Row style={{ width: '65%' }}>
          <h4>Reimpostazione password</h4>
          <section className="mt-4">
            Abbiamo provveduto a inviarti una mail con un link per reimpostare la password.
          </section>
        </Row>
      </Modal>
    </BasicLayout>
  );
};

export default withRouter(HomeView);
