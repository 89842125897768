import styled from 'styled-components';

export const LoaderContainer = styled.article`
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
`;

export const LoaderText = styled.h5`
  color: grey;
  margin-top: 1rem;
`;
