import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Row, RowProps } from 'react-bootstrap';

export const StyledRow: FunctionComponent<RowProps & { gradient?: boolean }> = styled(Row)`
  ${({ theme, gradient }) =>
    gradient &&
    css`
      background: linear-gradient(${theme.colors.secondary}, ${theme.colors.primary});
    `}
` as any;
