import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import Modal, { ModalProps } from '../../../../components/Modal';
import LoginForm from '../forms/LoginForm';

const LoginModal: FunctionComponent<ModalProps> = (props) => {
  return (
    <Modal gradient {...props}>
      <Row style={{ width: '65%' }}>
        <Col className="pl-0">
          <h4>Login</h4>
          <h6>MyDoctor Area</h6>
        </Col>
      </Row>
      <LoginForm type="full" />
    </Modal>
  );
};

export default LoginModal;
