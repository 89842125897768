import React from 'react';
import { Container } from './styled';
import { FunctionComponent } from 'react';
import { Card } from '../../../../components/styled/Card';
import { Col, Row } from 'react-bootstrap';
import { Image, Text } from '../../../../components/styled';
import PillIcon from '../../../../assets/pill.png';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

export interface MedicineAssignmentProps {
  insuline?: number;
  disabled?: boolean;
}

const MedicineAssignment: FunctionComponent<MedicineAssignmentProps> = ({
  insuline = 0.0,
  disabled = false,
}) => {
  return (
    <Container disabled={disabled}>
      <Card>
        <Row>
          <Col className="d-flex align-items-center justify-content-between">
            <Text color="softDark">Assegna medicina</Text>
            <Image src={PillIcon} width="40px" />
          </Col>
        </Row>
        <Row>
          <Input disabled={disabled} placeholder="Insulina" width={150} />
        </Row>
        <Row className="justify-content-end">
          <Button
            name="cancel-button"
            text="Cancella"
            btnStyle="link"
            color="danger"
            disabled={disabled}
            style={{ width: '90px' }}
          />
          <Button
            name="save-button"
            text="Salva"
            disabled={disabled}
            btnStyle="link"
            style={{ marginLeft: '0' }}
          />
        </Row>
      </Card>
    </Container>
  );
};

export default MedicineAssignment;
