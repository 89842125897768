import { takeEvery, put } from 'redux-saga/effects';
import { actions } from '.';
import firebase from 'firebase/app';
import { app } from '../../contexts/FirebaseContext';
import { generatePin } from 'generate-pin';
import { User } from './types';

function* signupSaga({
  payload: { username, email, password, nationalDoctorCode = '' },
}: ReturnType<typeof actions.signupRequest>) {
  try {
    yield (yield app.doCreateUserWithEmailAndPassword(
      email,
      password
    )).user?.sendEmailVerification();

    yield app.auth.currentUser?.updateProfile({ displayName: username });

    const pin = generatePin()[0];

    yield app.firestore.collection('users').doc().set({
      userId: app.auth.currentUser?.uid,
      role: 'doctor',
      enabled: false,
      patients: [],
      code: pin,
      nationalDoctorCode: nationalDoctorCode,
    });

    if (app.auth.currentUser)
      yield put(
        actions.signupSuccess({
          uid: app.auth.currentUser.uid,
          email: app.auth.currentUser.email,
          name: app.auth.currentUser.displayName,
          photoURL: app.auth.currentUser.photoURL,
          role: 'doctor',
          code: pin,
          nationalDoctorCode: nationalDoctorCode,
          enabled: false,
        })
      );
  } catch (exception) {
    yield put(actions.signupFailure(exception));
  }
}

function* loginSaga({ payload: { email, password } }: ReturnType<typeof actions.loginRequest>) {
  try {
    const authResp: firebase.auth.UserCredential = yield app.doSignInWithEmailAndPassword(
      email,
      password
    );

    const userResp: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield app.profile();

    const user = { ...authResp.user, ...(userResp.docs[0].data() as User) };

    if (user) {
      if (user.emailVerified)
        yield put(
          actions.loginSuccess({
            uid: user.uid,
            email: user.email,
            name: user.displayName || '',
            photoURL: user.photoURL,
            role: user.role,
            enabled: user.enabled,
            code: user.code,
            nationalDoctorCode: user.nationalDoctorCode || '',
          })
        );
      else throw new Error('Email non verificata');
    }
  } catch (exception) {
    yield put(actions.loginFailure(exception));
  }
}

function* logoutSaga({ payload }: ReturnType<typeof actions.logoutRequest>) {
  try {
    yield app.doSignOut();

    yield put(actions.logoutSuccess());
  } catch (exception) {
    yield put(actions.logoutFailure(exception));
  }
}

function* passwordRecoverySaga({ payload }: ReturnType<typeof actions.passwordRecoveryRequest>) {
  try {
    console.log('afafa', payload);
    yield app.doPasswordReset(payload.email);

    yield put(actions.passwordRecoverySuccess());
  } catch (exception) {
    yield put(actions.passwordRecoveryFailure(exception));
  }
}

export default [
  takeEvery(actions.signupRequest, signupSaga),
  takeEvery(actions.loginRequest, loginSaga),
  takeEvery(actions.logoutRequest, logoutSaga),
  takeEvery(actions.passwordRecoveryRequest, passwordRecoverySaga),
];
