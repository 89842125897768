import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export const selectIsFetching = createSelector(
  (state: RootState) => state.patients.isFetching,
  (isFetching) => isFetching
);

export const selectIsLogged = createSelector(
  (state: RootState) => Boolean(state.auth.user),
  (isLogged) => isLogged
);

export const selectPatients = createSelector(
  (state: RootState) => state.patients.patients,
  (patients) => patients
);

export const selectPatient = createSelector(
  (state: RootState) => state.patients.patient,
  (patient) => patient
);

export const selectIsEmpty = createSelector(
  (state: RootState) => state.patients.patients?.length == 0,
  (isEmpty) => isEmpty
);

export const selectIsError = createSelector(
  (state: RootState) => state.patients.isError,
  (isError) => isError
);

export const selectError = createSelector(
  (state: RootState) => state.patients.error,
  (error) => error
);
