import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer } from '../features/Auth';
import { reducer as patientsReducer } from '../features/Patients';

const rootReducer = combineReducers({
  auth: authReducer,
  patients: patientsReducer,
});

export default rootReducer;
