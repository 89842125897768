import React, { FunctionComponent, ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../Header';
import Footer from '../Footer';
import { StyledPage } from './styled';
import BgImage from '../../assets/bg.png';

export interface BasicLayoutProps {
  children: ReactNode;
  bgWhite?: boolean;
}

export const BasicLayout: FunctionComponent<BasicLayoutProps> = ({ children, bgWhite }) => {
  return (
    <StyledPage bgImage={bgWhite ? '' : BgImage}>
      <Row>
        <Header />
      </Row>
      {children}
      <Row>
        <Footer />
      </Row>
    </StyledPage>
  );
};
