import React, { FunctionComponent } from 'react';
import { PatientType } from '../../types';
import { StyledTableItem, StyledTableField } from './styled';
import ManImage from '../../../../assets/man.png';
import BelowIcon from '../../../../assets/below.png';
import WithinIcon from '../../../../assets/within.png';
import AboveIcon from '../../../../assets/above.png';
import { Image } from '../../../../components/styled';
import { GlucoseRange } from '../../../../definitions/glucoseRange';

export interface PatientProps {
  mode?: 'row' | 'square';
  patient: PatientType;
  glucoseRange: GlucoseRange;
  onClick?: (patientId: string) => void;
}

const Patient: FunctionComponent<PatientProps> = ({
  mode = 'row',
  patient,
  glucoseRange,
  onClick = (patientId: string) => {},
}) => {
  return (
    <>
      {
        <StyledTableItem key={`${patient.doctorCode}`} onClick={() => onClick(patient.id)}>
          <StyledTableField>
            <Image src={ManImage} width="50px" />
          </StyledTableField>
          <StyledTableField>{patient.displayName}</StyledTableField>
          <StyledTableField>
            {(patient.lastMeasurement && patient.lastMeasurement.value < glucoseRange.mid[0] && (
              <Image src={BelowIcon} />
            )) ||
              (patient.lastMeasurement && patient.lastMeasurement.value > glucoseRange.mid[1] && (
                <Image src={AboveIcon} />
              )) || <Image src={WithinIcon} />}
          </StyledTableField>
          <StyledTableField>{patient.doctorCode || '58975'}</StyledTableField>
          <StyledTableField>Associato</StyledTableField>
        </StyledTableItem>
      }
    </>
  );
};

export default Patient;
