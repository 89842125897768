import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin: 20px;
`;

export const StyledLogo = styled.img`
  width: 130px;
  height: 75px;
`;
