import React, { FunctionComponent } from 'react';
import { StyledForm, StyledFormProps } from './styled';

const Form: FunctionComponent<StyledFormProps> = ({ children, viewMode = 'column' }) => {
  return (
    <StyledForm autoComplete="off" onSubmit={(event) => event.preventDefault()} viewMode={viewMode}>
      {children}
    </StyledForm>
  );
};

export default Form;
