import React, { FunctionComponent } from 'react';
import BelowIcon from '../../../../assets/below.png';
import WithinIcon from '../../../../assets/within.png';
import AboveIcon from '../../../../assets/above.png';
import { Image, Paragraph } from '../../../../components/styled';

const GlucoseLegend: FunctionComponent = () => {
  return (
    <Paragraph
      className="d-inline-flex mt-2 justify-content-end align-items-center"
      color="primary"
    >
      <span className="mx-2">Bassa</span>
      <Image src={BelowIcon} height="15px" />
      <span className="mx-2">Normale</span>
      <Image src={WithinIcon} height="15px" />
      <span className="mx-2">Alta</span>
      <Image src={AboveIcon} height="15px" />
    </Paragraph>
  );
};

export default GlucoseLegend;
